import styled from "styled-components"
import {
  DASHBOARD_TYPE,
  Header,
  IconCardComponent,
  theme,
} from "@project/shared"
import { useTranslation } from "react-i18next"
import UsersIcons from "../../public/assets/icons/users.svg"
import CommunityIcon from "../../public/assets/icons/community.svg"
import YenIcon from "../../public/assets/icons/yen.svg"
import Head from "next/dist/shared/lib/head"
import withPrivateRoute from "../../withPrivateRoute"
import { useQuery } from "react-query"
import { fetchDashboardValues, fetchTransaction } from "../../services"
import { useContext } from "react"
import { AuthContext } from "../../utils"
import { BusinessType } from "../../utils/BusinessType"

const Container = styled.div`
  background-color: ${theme.background.colorSecondary3};
  min-height: 100vh;
  .container {
    margin: 35px;
    border: solid 1px ${theme.border.colorSecondary1};
    padding: 20px;
    border-radius: 5px;
    background-color: ${theme.background.colorSecondary1};
    padding-top: 25px;
    height: 448px;
    .displayCard1,
    .displayCard2 {
      display: flex;
      flex-direction: row;
    }
    .displayCard1 {
      justify-content: space-evenly;
    }
    .displayCard2 {
      justify-content: center;
      margin-top: 67px;
    }
  }
`
const HomePage = () => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const facility_id = user["custom:facilityId"]

  const { data: transactionData } = useQuery(
    ["merchant-data", facility_id],
    fetchDashboardValues,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      select: (data) => {
        return {
          totalRegisteredCustomer: data.todays_registrant_number,
          totalVoucherSold: data.todays_trading_amount,
          totalVoucherRedeem: data.todays_topup_amount,
        }
      },
    }
  )

  return (
    <Container>
      <Head>
        <title>{t("Home")}</title>
      </Head>
      <Header title={t("Home")} />
      <div className={"container"}>
        <div className={"displayCard1"}>
          <IconCardComponent
            type={DASHBOARD_TYPE.BUSINESS}
            label={t("service_sold")}
            icon={<CommunityIcon />}
            iconHeight={61}
            iconWidth={57}
            valueContent={
              transactionData?.totalVoucherSold
                ? Number(transactionData?.totalVoucherSold)?.toLocaleString() +
                  " 円"
                : "0 円"
            }
          />
          <IconCardComponent
            type={DASHBOARD_TYPE.BUSINESS}
            label={t("top_up")}
            icon={<YenIcon />}
            iconHeight={54.25}
            iconWidth={54.25}
            valueContent={
              transactionData?.totalVoucherRedeem
                ? Number(
                    transactionData?.totalVoucherRedeem
                  )?.toLocaleString() + " 円"
                : "0 円"
            }
          />
        </div>
      </div>
    </Container>
  )
}
export default withPrivateRoute(HomePage)
